export class Preloader {
    constructor() {
        this.preloader = document.querySelector('.loader');
    }

    show() {
        this.preloader.style.display = 'flex';
    }

    hide() {
        this.preloader.style.display = 'none';
    }
}
