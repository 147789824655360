import {CustomSelect} from './CustomSelect';
import {Datepicker} from './Datepicker';
import {OpenShift} from './OpenShift';
import {ExitShift} from './ExitShift';
import {FillReport} from './FillReport';
import {SendReport} from './SendReport';
import {TransactionCrud} from './TransactionCrud';
import {Tooltip} from './Tooltip';
import {Preloader} from './Preloader';
import {Report} from './Report';
import {Mask} from './Mask';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.custom-select').forEach((select) => {
        let customSelect = new CustomSelect(select);
        customSelect.setup();
    });

    document.querySelectorAll('input.custom-date').forEach((dateField) => {
        let datepicker = new Datepicker(dateField);
        datepicker.setup();
    });

    const mask = new Mask('.numeric-mask');
    mask.setup();

    let openShift = new OpenShift('open-shift-form');
    openShift.setup();

    let sendReport = new SendReport('send-report-dialog', 'shift-form');
    sendReport.setup();
    sendReport.enableButton();

    let exitButton = new ExitShift('exit-shift', 'close-dialog', sendReport);
    exitButton.setup();

    let fillReport = new FillReport('shift-form', sendReport, new Preloader(), mask);
    fillReport.setup();

    let transactionCrud = new TransactionCrud('transactions-table', mask);
    transactionCrud.setup();

    let tooltip = new Tooltip('tooltip');
    tooltip.setup();

    let report = new Report('report-form');
    report.setup();
});
