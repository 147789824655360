export class Report {
    constructor(formId) {
        this.form = document.querySelector('#' + formId);
    }

    setup() {
        if (!this.form) return;
        this.form.onsubmit = async (e) => {
            e.preventDefault();
            if (
                e.submitter ===
                document.querySelector(
                    '#report-form > div.air-datepicker.-inline- > div.air-datepicker--buttons > div > button'
                )
            )
                return;

            let dateRange = this.form
                .querySelector('.custom-date')
                .value.split('-')
                .map((val) => val.trim());

            if (!dateRange[1]) dateRange[1] = dateRange[0];

            let data = {
                dateFrom: dateRange[0],
                dateTo: dateRange[1],
            };

            let isValid = this.#validate(dateRange);

            if (isValid) {
                await this.#getReport(data);
            }
        };
    }

    #validate(dateRange) {
        let isFilled;
        if (dateRange[0] && dateRange[1]) {
            isFilled = true;
        }
        if (!isFilled) {
            this.form.querySelector('.report__error').innerText = 'Выберите дату';
        } else {
            this.form.querySelector('.report__error').innerText = '';
        }
        return isFilled;
    }

    async #getReport(date) {
        let data = await fetch('/api/v1/reports/generate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(date),
        });

        if (data.ok) {
            let response = await data.json();

            let tooltip = document.querySelector('.tooltip');
            tooltip.style.opacity = 1;

            let offset = response.limit;

            let tooltipStep = tooltip.querySelector('.tooltip__footer > span');
            let tooltipBar = tooltip.querySelector('.tooltip__bar');

            let link;

            for (let i = 1; i <= response.steps; i++) {
                let isLast = i === response.steps;

                tooltipStep.innerText = `Этап ${i} из ${response.steps} ...`;
                tooltipBar.style.width = `${(100 / response.steps) * i}%`;

                let report = await fetch('/api/v1/reports/generate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify({...date, offset: offset * i - response.limit, isLast: isLast}),
                });

                link = await report.json();
            }

            if (link.link) {
                let downloadLink = document.createElement('a');
                downloadLink.href = link.link;
                document.body.appendChild(downloadLink);
                downloadLink.click();

                tooltip.style.opacity = 0;

                tooltipStep.innerText = `Этап 0 из 0 ...`;
                tooltipBar.style.width = 0;
            }
        }
    }
}
