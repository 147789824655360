export class Tooltip {
    constructor(commonTooltipClass) {
        this.commonTooltipClass = commonTooltipClass;
        this.tooltipInits = document.querySelectorAll('.' + commonTooltipClass + '__button');
    }

    setup() {
        this.#addTooltipsInitEvents();
    }

    #addTooltipsInitEvents() {
        let _this = this;

        this.tooltipInits.forEach((i) => {
            i.addEventListener('click', () => {
                let tipCloud = i
                    .closest('.' + _this.commonTooltipClass)
                    .querySelector('.' + _this.commonTooltipClass + '__cloud');

                tipCloud.classList.toggle('active');
            });
        });
    }
}
