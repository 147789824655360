export class OpenShift {
    constructor(formId) {
        this.form = document.querySelector('#' + formId);
    }

    setup() {
        if (!this.form) {
            return;
        }

        this.#addSubmitEvent();
    }

    #addSubmitEvent() {
        let _this = this;
        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            let formData = new FormData(_this.form);

            if (!formData.get('restaurantId')) {
                document.querySelector('.select__button').classList.add('select__button_error');
                return;
            }

            _this.#sendShiftRequest(formData);
        });
    }

    #sendShiftRequest(formData) {
        fetch('/api/v1/shift/open', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось открыть смену');
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }
}
