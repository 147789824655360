export class CustomSelect {
    constructor(select) {
        this.select = select;
    }

    static build(select, selectInput) {
        let self = new CustomSelect(select);
        self.selectInput = selectInput;
        return self;
    }

    setup() {
        if (!this.select) {
            return;
        }

        this.#wrap(this.select);
        this.#createSelectInput(this.select);
        this.#createValueList(this.select);
        this.#closeListOnOutBoundariesClick();

        this.#disableDependedSelect();
    }

    #wrap(select) {
        let wrapper = document.createElement('div');
        wrapper.classList.add('select');
        select.parentElement.insertBefore(wrapper, select);
        wrapper.appendChild(select);
        select.style.display = 'none';
        wrapper.dataset.name = select.name;

        this.wrapper = wrapper;
    }

    #disableDependedSelect() {
        this.isDisabled = false;

        if (this.select.hasAttribute('data-depends-on')) {
            this.isDisabled = true;
            this.selectInput.setAttribute('disabled', 'disabled');
        }
    }

    #enableDependedSelect() {
        this.isDisabled = false;

        if (this.select.hasAttribute('data-depends-on')) {
            this.selectInput.removeAttribute('disabled');
        }
    }

    #createSelectInput(select) {
        let divSelect = document.createElement('div');
        divSelect.textContent = select.dataset.name;
        divSelect.classList.add('select__button');
        this.wrapper.appendChild(divSelect);

        this.selectInput = divSelect;

        this.selectInput.addEventListener('click', (e) => {
            e.stopPropagation();

            if (this.selectInput.hasAttribute('disabled')) {
                return;
            }

            this.#closeAllLists();

            if (this.optionsElement.style.display === 'block') {
                this.#closeList();
            } else {
                this.#callSelectList();
            }
        });
    }

    #createValueList(select) {
        let _this = this;

        let divOptionsWrapper = document.createElement('div');
        divOptionsWrapper.classList.add('select__options-wrapper');
        divOptionsWrapper.style.display = 'none';
        this.wrapper.appendChild(divOptionsWrapper);

        let divOptionsList = document.createElement('div');
        divOptionsList.classList.add('select__options-list');
        divOptionsWrapper.appendChild(divOptionsList);

        this.optionsElement = divOptionsWrapper;

        Array.prototype.forEach.call(select.options, (option) => {
            if (!option.value) {
                return;
            }

            let divOption = document.createElement('div');
            divOption.dataset.value = option.value;
            divOption.textContent = option.textContent;
            divOption.classList.add('select__option');

            divOptionsList.appendChild(divOption);
        });
        divOptionsList.addEventListener('click', (e) => {
            _this.#selectOption(select, e.target);
            _this.#linkOptionToAnotherSelect(select, e.target);
        });
    }

    #callSelectList() {
        this.optionsElement.style.display = 'block';
        this.optionsElement.style.top = 'calc(100% + 6px)';
    }

    #selectOption(originalSelect, selected) {
        originalSelect.value = selected.dataset.value;
        this.selectInput.textContent = selected.textContent;
        this.#closeList();
        this.selectInput.classList.add('select__button_with-value');
        this.selectInput.classList.remove('select__button_error');

        let event = new Event('change');
        this.selectInput.dispatchEvent(event);
    }

    #linkOptionToAnotherSelect(originalSelect, selected) {
        let originalOption = originalSelect.querySelector('option[value="' + selected.dataset.value + '"]');
        let dependedName = originalOption.getAttribute('data-depends-to');

        if (originalOption.hasAttribute('data-depends-to')) {
            let availableValues = originalOption.getAttribute('data-available-values').split(';');

            let dependedSelect = selected.closest('form').querySelector('div[data-name="' + dependedName + '"]');
            let isContainsSomething = false;

            dependedSelect.querySelectorAll('div.select__option').forEach((o) => {
                if (!availableValues.includes(o.dataset.value)) {
                    o.style.display = 'none';
                } else {
                    o.style.display = 'block';
                    isContainsSomething = true;
                }
            });

            if (isContainsSomething) {
                dependedSelect.querySelector('div.select__button').removeAttribute('disabled');
            } else {
                dependedSelect.querySelector('div.select__button').setAttribute('disabled', 'disabled');
            }
            dependedSelect.querySelector('div.select__button').classList.remove('select__button_with-value');
            dependedSelect.querySelector('select').value = '';
            dependedSelect.querySelector('div.select__button').textContent =
                dependedSelect.querySelector('select').dataset.name;
        }
    }

    #closeListOnOutBoundariesClick() {
        let _this = this;
        document.addEventListener('click', (e) => {
            let isWithinBoundaries = e.composedPath().includes(_this.optionsElement);
            let isDisplayed = _this.optionsElement.style.display === 'block';
            let isInputClick = e.target === _this.selectInput;

            if ((!isWithinBoundaries || isInputClick) && isDisplayed) {
                _this.#closeList();
            }
        });
    }

    #closeAllLists() {
        document.querySelectorAll('.select__options-wrapper').forEach((l) => {
            if (l === this.optionsElement) {
                return;
            }

            l.style.display = 'none';
        });
    }

    #closeList() {
        this.optionsElement.style.display = 'none';
    }

    clearValue(disableDepended = true) {
        this.select.value = '';
        this.selectInput.textContent = this.select.dataset.name;
        this.selectInput.classList.remove('select__button_with-value');
        this.selectInput.classList.remove('select__button_error');

        if (disableDepended) this.#disableDependedSelect();
        else this.#enableDependedSelect();
    }

    setValue(value, textValue, disableDepended = true) {
        this.select.value = value;
        this.selectInput.textContent = textValue;
        this.selectInput.classList.add('select__button_with-value');
        this.selectInput.classList.remove('select__button_error');

        if (disableDepended) this.#disableDependedSelect();
        else this.#enableDependedSelect();
    }
}
