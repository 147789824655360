export class FillReport {
    constructor(formId, sendReport, preloader, mask) {
        this.form = document.querySelector('#' + formId);
        this.sendReportHandler = sendReport;
        this.preloaderHandler = preloader;
        this.mask = mask;
    }

    setup() {
        if (!this.form) {
            return;
        }

        this.#addInputAction();
        this.#addSaveBtnEvent();
    }

    #addSaveBtnEvent() {
        let _this = this;

        this.form.querySelectorAll('#save').forEach((button) => {
            button.addEventListener('click', () => {
                _this.form.querySelectorAll('input[type=text]:not([disabled])').forEach((input) => {
                    _this.#sendInputParam(input);
                });
            });
        });
    }

    #addInputAction() {
        let _this = this;

        this.form.querySelectorAll('input[type=text]').forEach((input) => {
            input.addEventListener(
                'keyup',
                _this.#delay(function () {
                    _this.#sendInputParam(input);
                }, 1000)
            );
        });

        let employeesInput = this.form.querySelector('textarea[name="employees"]');
        employeesInput.addEventListener(
            'keyup',
            _this.#delay(function () {
                _this.#sendEmployeeData(employeesInput);
            }, 1500)
        );
    }

    #sendInputParam(input) {
        this.preloaderHandler.show();
        fetch('/api/v1/shift/set-params', {
            method: 'POST',
            headers: {
                'Session-Id': document.querySelector('#sessid').value,
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                [input.name]: parseFloat(this.mask.getUnmaskedValueByElement(input)),
            }),
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    input.closest('.shift-card').classList.add('shift-card_filled');
                    this.sendReportHandler.enableButton();
                } else {
                    alert('Не удалось установить параметр');
                    console.error(r.message);
                }
            })
            .then(() => {
                this.#getAutoGeneratedFields();
                this.preloaderHandler.hide();
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #sendEmployeeData(input) {
        this.preloaderHandler.show();
        fetch('/api/v1/shift/set-employees', {
            method: 'POST',
            headers: {
                'Session-Id': document.querySelector('#sessid').value,
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                [input.name]: input.value,
            }),
        })
            .then((r) => r.json())
            .then((r) => {
                this.preloaderHandler.hide();
                if (!r.isSuccess) {
                    alert('Не удалось добавить информацию о сотрудниках');
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #getAutoGeneratedFields() {
        fetch('/api/v1/shift/get-params', {
            method: 'GET',
            headers: {
                'Session-Id': document.querySelector('#sessid').value,
            },
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    this.form.querySelector('input[name="transactionsSum"]').value =
                        r.data.transactionsSum > 0 ? `+${r.data.transactionsSum}` : r.data.transactionsSum;
                    this.form.querySelector('input[name="summary"]').value = r.data.summary;
                    this.form.querySelector('input[name="cashMismatch"]').value =
                        r.data.cashMismatch > 0 ? `+${r.data.cashMismatch}` : r.data.cashMismatch;
                } else {
                    console.error('Автоматическое получение параметров не удалось: ' + r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #delay(fn, ms) {
        let timer = 0;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(fn.bind(this, ...args), ms || 0);
        };
    }
}
