import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';

export class Datepicker {
    constructor(dateField) {
        this.dateField = dateField;
    }

    setup() {
        if (!this.dateField) {
            return;
        }

        let options = {
            autoClose: true,
            position: 'right center',
            buttons: ['clear'],
            onSelect({datepicker}) {
                let event = new Event('change');
                datepicker.$el.dispatchEvent(event);
            },
        };

        if (this.dateField.classList.contains('custom-date_modal')) {
            let modal = this.dateField.closest('dialog');
            let datepickerContainer = document.createElement('div');
            datepickerContainer.className = 'modal-air-datepicker';
            modal.prepend(datepickerContainer);
            options.container = datepickerContainer;
        }

        if (this.dateField.classList.contains('custom-date_time')) {
            options.timepicker = true;
            options.minutesStep = 5;
        }

        if (this.dateField.classList.contains('custom-date_range')) {
            options.range = true;
            options.multipleDatesSeparator = ' - ';
        }
        if (this.dateField.classList.contains('custom-date_inline')) {
            options.inline = true;
        }

        if (this.dateField.classList.contains('custom-date_block-future')) {
            options.maxDate = new Date();
        }

        new AirDatepicker(this.dateField, options);
    }
}
