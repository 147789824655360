export class SendReport {
    constructor(sendModalId, formId) {
        this.sendModal = document.querySelector('#' + sendModalId);
        this.form = document.querySelector('#' + formId);
    }

    setup() {
        if (!this.sendModal) {
            return;
        }

        this.#addSubmitEvent();
        this.#addModalClickEvents();
    }

    showModal() {
        if (!this.form) {
            window.location.href = '/shift-operator/shift/';
        } else {
            this.sendModal.showModal();
        }
    }

    enableButton() {
        if (!this.form) {
            return;
        }

        let fields = this.form.querySelectorAll('input.shift-card__input');

        let allFilled = true;
        fields.forEach((field) => {
            if (!allFilled) {
                return;
            }

            allFilled = field.value !== '';
        });

        this.form.querySelector('#close-shift-btn').disabled = !allFilled;
    }

    #addSubmitEvent() {
        if (!this.form) {
            return;
        }

        let _this = this;
        this.form.querySelector('#close-shift-btn').addEventListener('click', (e) => {
            e.preventDefault();
            _this.showModal();
        });
    }

    #addModalClickEvents() {
        let _this = this;

        this.sendModal.querySelector('.form__close-btn').addEventListener('click', () => {
            _this.sendModal.close();
        });
        this.sendModal.querySelector('.form__btn_close').addEventListener('click', () => {
            _this.sendModal.close();
        });
        this.sendModal.querySelector('#modal-confirm-send-btn').addEventListener('click', () => {
            _this.#sendReportRequest();
        });
    }

    #sendReportRequest() {
        fetch('/api/v1/shift/close', {
            method: 'POST',
            headers: {
                'Session-Id': document.querySelector('#sessid').value,
            },
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось закрыть смену');
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }
}
