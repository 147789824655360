import {CustomSelect} from './CustomSelect';

export class TransactionCrud {
    constructor(tableId, mask) {
        this.table = document.querySelector('#' + tableId);
        this.mask = mask;
    }

    setup() {
        if (!this.table) {
            return;
        }

        let _this = this;

        this.#configureModals();
        this.#configureModalForms();

        let addBtn = document.querySelector('#add-transaction');
        this.#addBtnCreateTransactionClickEvent(addBtn);

        let editBtns = this.table.querySelectorAll('.table__button_edit');
        editBtns.forEach((editBtn) => {
            _this.#addBtnEditTransactionClickEvent(editBtn);
        });

        this.transactionRemoveModal = document.querySelector('#delete-dialog');
        this.#addDeleteModalClickEvents();

        let deleteBtns = this.table.querySelectorAll('.table__button_delete');
        deleteBtns.forEach((deleteBtn) => {
            _this.#addBtnDeleteClickEvent(deleteBtn);
        });
    }

    #configureModals() {
        this.chooseAddedTypeModal = document.querySelector('#choose-dialog');
        this.chooseAddTransactionModal = document.querySelector('#transaction-form-dialog');

        this.transactionModal = document.querySelector('#transaction-form-dialog');
        this.transactionExpenseModal = document.querySelector('#transaction-expense-form-dialog');
        this.transactionIncomingModal = document.querySelector('#transaction-incoming-form-dialog');
        this.transactionEditExpenseModal = document.querySelector('#transaction-edit-expense-form-dialog');
        this.transactionEditIncomingModal = document.querySelector('#transaction-edit-incoming-form-dialog');

        this.reservationTypeModal = document.querySelector('#reservation-type-dialog');
        this.addReservationModal = document.querySelector('#add-reservation-dialog');
        this.returnReservationTypeModal = document.querySelector('#return-reservation-dialog');
        this.transferReservationTypeModal = document.querySelector('#transfer-reservation-dialog');
        this.writeOffReservationTypeModal = document.querySelector('#write-off-reservation-dialog');

        this.#addModalFlowClicks();
        this.#addTransactionTypeModalFlow();
        this.#addModalCloseClickEvent();
    }

    #configureModalForms() {
        let forms = [];

        forms.push(this.transactionExpenseModal.querySelector('form'));
        forms.push(this.transactionIncomingModal.querySelector('form'));
        forms.push(this.transactionEditExpenseModal.querySelector('form'));
        forms.push(this.transactionEditIncomingModal.querySelector('form'));

        forms.push(this.addReservationModal.querySelector('form'));
        forms.push(this.returnReservationTypeModal.querySelector('form'));
        forms.push(this.transferReservationTypeModal.querySelector('form'));
        forms.push(this.writeOffReservationTypeModal.querySelector('form'));

        forms.forEach((form) => {
            this.#addSubmitEvent(form);
            this.#addInputRemoveErrorsLogic(form);
        });

        this.#addDateFiltersHandler();
        this.#addLimitDisplayHandler();
    }

    #addModalFlowClicks() {
        let _this = this;

        this.chooseAddedTypeModal.querySelector('#modal-add-reservation-btn').addEventListener('click', () => {
            _this.reservationTypeModal.showModal();
            _this.chooseAddedTypeModal.close();
        });
        this.chooseAddedTypeModal.querySelector('#modal-add-transaction-btn').addEventListener('click', () => {
            let typeSelect = _this.transactionModal.querySelector('.select');
            let typeHandler = CustomSelect.build(
                typeSelect.querySelector('select'),
                typeSelect.querySelector('.select__button')
            );

            typeHandler.clearValue();

            _this.chooseAddTransactionModal.showModal();
            _this.chooseAddedTypeModal.close();
        });

        this.reservationTypeModal.querySelector('#modal-reservation-add-btn').addEventListener('click', () => {
            _this.addReservationModal.showModal();
            _this.reservationTypeModal.close();
        });
        this.reservationTypeModal.querySelector('#modal-reservation-write-off-btn').addEventListener('click', () => {
            _this.writeOffReservationTypeModal.showModal();
            _this.reservationTypeModal.close();
        });
        this.reservationTypeModal.querySelector('#modal-reservation-transfer-btn').addEventListener('click', () => {
            _this.transferReservationTypeModal.showModal();
            _this.reservationTypeModal.close();
        });
        this.reservationTypeModal.querySelector('#modal-reservation-return-btn').addEventListener('click', () => {
            _this.returnReservationTypeModal.showModal();
            _this.reservationTypeModal.close();
        });
    }

    #addTransactionTypeModalFlow() {
        let _this = this;

        let selectsExpense = document.querySelectorAll(
            ".modal_transaction_add .select__option[data-value='CASH_EXPENSE'], .modal_transaction_add .select__option[data-value='NON_CASH_EXPENSE']"
        );
        let selectsIncoming = document.querySelectorAll(
            ".modal_transaction_add .select__option[data-value='CASH_INCOMING'], .modal_transaction_add .select__option[data-value='NON_CASH_INCOMING'], .modal_transaction_add .select__option[data-value='CARD_TRANSFER']"
        );

        selectsExpense.forEach((s) => {
            let select = _this.transactionExpenseModal.querySelector('.select');
            let handler = CustomSelect.build(select.querySelector('select'), select.querySelector('.select__button'));

            s.addEventListener('click', () => {
                handler.setValue(s.dataset.value, s.textContent);
                _this.transactionExpenseModal.showModal();
                _this.transactionIncomingModal.close();
                _this.transactionModal.close();
            });
        });
        selectsIncoming.forEach((s) => {
            let select = _this.transactionIncomingModal.querySelector('.select');
            let handler = CustomSelect.build(select.querySelector('select'), select.querySelector('.select__button'));

            s.addEventListener('click', () => {
                handler.setValue(s.dataset.value, s.textContent);
                _this.transactionIncomingModal.showModal();
                _this.transactionExpenseModal.close();
                _this.transactionModal.close();
            });
        });
    }

    #addModalCloseClickEvent() {
        document.querySelectorAll('.form__close-btn').forEach((btn) => {
            btn.addEventListener('click', () => {
                btn.closest('dialog').close();
            });
        });
    }

    #addDateFiltersHandler() {
        document.querySelectorAll('.custom-date_filter').forEach((f) => {
            f.addEventListener('change', () => {
                let list = f.closest('form').querySelector('.form-list');

                let range = f.value.split(' - ');
                let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

                let from = new Date(range.shift().replace(pattern, '$3-$2-$1') + 'T00:00:00').getTime();
                let to = new Date(range.shift().replace(pattern, '$3-$2-$1') + 'T00:00:00').getTime();

                let displayEverything = f.value.length === 0;

                list.querySelectorAll('label').forEach((o) => {
                    let strDate = o.firstElementChild.dataset.date;
                    let date = new Date(strDate.replace(pattern, '$3-$2-$1') + 'T00:00:00').getTime();

                    if ((date >= from && date <= to) || displayEverything) {
                        o.style.display = 'flex';
                    } else {
                        o.style.display = 'none';
                    }
                });
            });
        });
    }

    #addLimitDisplayHandler() {
        let forms = [];

        forms.push(this.transactionExpenseModal.querySelector('form'));
        forms.push(this.transactionEditExpenseModal.querySelector('form'));

        forms.forEach((f) => {
            let sumInputWrapper = f.querySelector('input[name="sum"]').parentElement;

            f.querySelector('.select[data-name="groupId"] .select__button')?.addEventListener('change', (e) => {
                if (e.target.textContent === 'Питание') {
                    sumInputWrapper.querySelector('.form__input').classList.add('form__input_limit');
                    sumInputWrapper.querySelector('.form__input-msg').style.display = 'block';
                } else {
                    sumInputWrapper.querySelector('.form__input').classList.remove('form__input_limit');
                    sumInputWrapper.querySelector('.form__input-msg').style.display = 'none';
                }
            });
        });
    }

    #addBtnCreateTransactionClickEvent(btn) {
        let _this = this;

        btn.addEventListener('click', () => {
            let transactionModal = _this.chooseAddedTypeModal;

            transactionModal.showModal();
        });
    }

    #addBtnEditTransactionClickEvent(btn) {
        let _this = this;

        btn.addEventListener('click', () => {
            let tableRow = btn.closest('.table__row');
            let transactionId = tableRow.dataset.id;
            let transactionModal =
                tableRow.dataset.editType === 'incoming'
                    ? _this.transactionEditIncomingModal
                    : _this.transactionEditExpenseModal;

            transactionModal.querySelectorAll('div.select').forEach((select) => {
                let selectElement = select.querySelector('select');
                let handler = CustomSelect.build(selectElement, select.querySelector('.select__button'));

                let cell = tableRow.querySelector('.table__cell[data-type="' + selectElement.name + '"]');

                handler.setValue(cell.dataset.value, cell.textContent, false);
            });
            transactionModal.querySelectorAll('input').forEach((input) => {
                let cell = tableRow.querySelector('.table__cell[data-type="' + input.name + '"]');
                if (!cell) {
                    return;
                }

                input.value = cell.dataset.value;
            });

            transactionModal.querySelector('.form__title').textContent = 'Изменить транзакцию';
            transactionModal.querySelector('form').dataset.action =
                tableRow.dataset.editType === 'incoming' ? 'edit_incoming' : 'edit_transaction';
            transactionModal.querySelector('form').dataset.id = transactionId;
            transactionModal.querySelector('input[type=submit]').value = 'Изменить';

            transactionModal.showModal();
        });
    }

    #addSubmitEvent(form) {
        let _this = this;
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            let formData = new FormData(form);
            let validated = true;

            let requiredFields = form.querySelectorAll('.required');

            requiredFields.forEach((f) => {
                if (!formData.get(f.name)) {
                    if (f.classList.contains('custom-select')) {
                        form.querySelector('.select[data-name=' + f.name + '] > .select__button').classList.add(
                            'select__button_error'
                        );
                    } else {
                        form.querySelector('.form__input[name=' + f.name + ']').classList.add('form__input_error');
                    }

                    validated = false;
                }
            });

            if (!validated) {
                return;
            }

            switch (form.dataset.action) {
                case 'add_transaction':
                    _this.#addTransactionRequest(formData);
                    break;
                case 'add_incoming':
                    _this.#addIncomingRequest(formData);
                    break;
                case 'edit_transaction':
                    _this.#editTransactionRequest(form.dataset.id, formData, '#transaction-edit-expense-form-dialog');
                    break;
                case 'edit_incoming':
                    _this.#editTransactionRequest(form.dataset.id, formData, '#transaction-edit-incoming-form-dialog');
                    break;
                case 'add_reservation':
                    _this.#addReservationRequest(formData);
                    break;
                case 'return_reservation':
                    _this.#returnReservationsRequest(formData.get('reservationId'), formData);
                    break;
                case 'write_off_reservation':
                    _this.#writeOffReservationsRequest(formData);
                    break;
                case 'transfer_reservation':
                    _this.#transferReservationsRequest(formData.get('reservationId'), formData);
                    break;
            }
        });
    }

    #addInputRemoveErrorsLogic(form) {
        form.querySelectorAll('.form__input').forEach((input) => {
            input.addEventListener('click', () => {
                input.classList.remove('form__input_error');
            });
        });
    }

    #addDeleteModalClickEvents() {
        let _this = this;

        this.transactionRemoveModal.querySelector('.form__close-btn').addEventListener('click', () => {
            _this.transactionRemoveModal.close();
        });
        this.transactionRemoveModal.querySelector('.form__btn_close').addEventListener('click', () => {
            _this.transactionRemoveModal.close();
        });
        this.transactionRemoveModal.querySelector('#modal-delete-transaction-btn').addEventListener('click', (e) => {
            let transactionId = e.target.dataset.id;
            _this.#removeTransactionRequest(transactionId);
        });
    }

    #addBtnDeleteClickEvent(btn) {
        let _this = this;

        btn.addEventListener('click', () => {
            let tableRow = btn.closest('.table__row');
            let transactionId = tableRow.dataset.id;

            _this.transactionRemoveModal.showModal();
            _this.transactionRemoveModal.querySelector('#modal-delete-transaction-btn').dataset.id = transactionId;
        });
    }

    #addTransactionRequest(formData) {
        formData.set(
            'sum',
            this.mask.getUnmaskedValueByElement(document.querySelector('#transaction-expense-form-dialog [name=sum]'))
        );

        fetch('/api/v1/transaction/add', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось добавить транзакцию: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #addIncomingRequest(formData) {
        formData.set(
            'sum',
            this.mask.getUnmaskedValueByElement(document.querySelector('#transaction-incoming-form-dialog [name=sum]'))
        );

        fetch('/api/v1/transaction/add/incoming', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось добавить транзакцию: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #addReservationRequest(formData) {
        formData.set(
            'sum',
            this.mask.getUnmaskedValueByElement(document.querySelector('#add-reservation-dialog [name=sum]'))
        );
        fetch('/api/v1/transaction/add/reservation', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось добавить бронирование: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #writeOffReservationsRequest(formData) {
        formData.append('reservationIds', formData.getAll('reservationIds[]').join(','));

        fetch('/api/v1/reservation/write-off', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось добавить бронирование: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #returnReservationsRequest(reservationId, formData) {
        fetch('/api/v1/reservation/' + reservationId + '/return', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось отменить бронирование: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #transferReservationsRequest(reservationId, formData) {
        fetch('/api/v1/reservation/' + reservationId + '/transfer', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось перенести бронирование: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #editTransactionRequest(transactionId, formData, formId) {
        formData.set('sum', this.mask.getUnmaskedValueByElement(document.querySelector(formId + ' [name=sum]')));

        fetch('/api/v1/transaction/' + transactionId + '/update', {
            method: 'POST',
            body: formData,
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось изменить транзакцию: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }

    #removeTransactionRequest(transactionId) {
        fetch('/api/v1/transaction/' + transactionId + '/delete', {
            method: 'POST',
            headers: {
                'Session-Id': document.querySelector('input[name="Session-Id"]').value,
            },
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось удалить транзакцию: ' + r.message);
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }
}
