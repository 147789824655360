import IMask from 'imask';

export class Mask {
    className;
    elements = [];
    constructor(className) {
        this.className = className;
    }
    setup() {
        let _this = this;
        document.querySelectorAll(this.className).forEach((input) => {
            let min = 0;
            if (input.classList.contains(_this.className.substring(1) + '_min-not-zero')) {
                min = 0.01;
            }

            this.elements.push(
                IMask(input, {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: ' ',
                    padFractionalZeros: false,
                    normalizeZeros: true,
                    radix: '.',
                    mapToRadix: ['.', ','],

                    min: min,
                    max: 999999.99,
                    autofix: true,
                })
            );
        });
    }
    getUnmaskedValueByElement(input) {
        let data = this.elements.find((elementMask) => elementMask.el.input === input);
        return data._unmaskedValue;
    }
}
