export class ExitShift {
    constructor(exitBtnId, exitModalId, sendReport) {
        this.exitBtn = document.querySelector('#' + exitBtnId);
        this.exitModal = document.querySelector('#' + exitModalId);
        this.sendReport = sendReport;
    }

    setup() {
        if (!this.exitBtn) {
            return;
        }
        if (!this.exitModal) {
            return;
        }

        this.#addClickEvents();
    }

    #addClickEvents() {
        let _this = this;
        this.exitBtn.addEventListener('click', () => {
            _this.exitModal.showModal();
        });
        this.exitModal.querySelector('.form__close-btn').addEventListener('click', () => {
            _this.exitModal.close();
        });
        this.exitModal.querySelector('#modal-send-report-btn').addEventListener('click', () => {
            _this.sendReport.showModal();
            _this.exitModal.close();
        });
        this.exitModal.querySelector('#modal-exit-shift-btn').addEventListener('click', () => {
            _this.#sendExitRequest();
        });
    }

    #sendExitRequest() {
        fetch('/api/v1/shift/exit', {
            method: 'POST',
            headers: {
                'Session-Id': document.querySelector('#sessid').value,
            },
        })
            .then((r) => r.json())
            .then((r) => {
                if (r.isSuccess) {
                    window.location.reload();
                } else {
                    alert('Не удалось выйти из смены');
                    console.error(r.message);
                }
            })
            .catch((e) => console.error('Ошибка: ', e));
    }
}
